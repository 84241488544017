.bntGroup {
    display: flex;
    gap: 32px;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 1200px) {
    .bntGroup {
        gap: 16px;
    }
}