.reviewsItem {
    width: 100%;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
    background: var(--page-bg-color);
    padding: 76px 40px 42px;
    position: relative;
    border-radius: 8px;
}

.reviewsItem::before {
    content: '';
    width: 40px;
    height: 30px;
    display: block;
    position: absolute;
    top: 26px;
    left: 40px;
    background: url("../../../assets/icons/quote.svg") no-repeat center / cover;
}

.reviewsItem p {
    margin: 0 0 16px;
    font-family: var(--text-font);
    font-weight: 400;
    font-size: var(--text-font-size);
    line-height: var(--text-line-height);
    color: rgba(121, 125, 120, .8);
}

.reviewsItem button {
    display: inline-flex;
    background: transparent;
    padding: 0;
    margin: 0;
    border: none;
    font-family: var(--text-font);
    font-size: var(--text-font-size);
    line-height: var(--text-line-height);
    font-weight: 600;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: rgba(129, 70, 36, .8);
}

.reviewsItem button:hover {
    color: var(--secondary-heading-color);
}

.reviewsItem span {
    font-weight: 600;
    font-family: var(--text-font);
    font-size: var(--text-font-size);
    line-height: var(--text-line-height);
    color: rgba(121, 125, 120, .8);
    margin: 0 0 16px;
}

.reviewsItem div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}

.reviewsItem img {
    display: block;
    width: 110px;
    height: 110px;
    border-radius: 8px;
}

@media (max-width: 1200px) {
    .reviewsItem {
        padding: 76px 20px 32px;
    }
}