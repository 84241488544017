.footer {
    padding: 32px 0 64px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.footer__container {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    justify-content: space-between;
    grid-template-areas: "credentials social nav";
}

.footer__col {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.footer__colCredentials {
    grid-area: credentials;
    align-items: flex-start;
}

.footer__colSocial {
    grid-area: social;
    align-items: center;
    text-align: center;
}

.footer__colSocial>span {
    margin-top: auto;
}

.footer__colNav {
    grid-area: nav;
    align-items: flex-end;
    text-align: right;
}

.footer__colCredentials a,
.footer__colNav a {
    font-family: var(--text-font);
    font-size: var(--text-font-size);
    line-height: var(--text-line-height);
    color: var(--text-color);
    font-weight: 400;
    text-decoration: none;
}

.footer__colCredentials a:hover,
.footer__colNav a:hover,
.footer__colCredentials a:hover,
.footer__colNav a:visited {
    color: var(--text-color);
    text-decoration: none;
}

@media (max-width: 1200px) {
    .footer__container {
        grid-template-columns: minmax(0, 1fr);
        gap: 32px;
        grid-template-areas: "social" "credentials" "nav"  ;
    }


.footer__colCredentials {
    align-items: center;
    text-align: center;
}

.footer__colNav {
    align-items: center;
    text-align: center;
}
}