.social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-weight: 500;
  font-size: var(--big-btn-font-size);
  line-height: var(--text-line-height);
  font-family: var(--btn-font);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: var(--btn-color);
}

.socialSmall {
  display: inline-flex;
}

.socialSmall svg {
  width: 32px;
  height: 32px;
}