.included {
    padding: 80px 0;
    position: relative;
    background: var(--secondary-heading-color);
}

.included::before {
    content: '';
    display: block;
    width: 290px;
    height: 451px;
    background: url('../../assets/left-included.svg') no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
}

.included::after {
    content: '';
    display: block;
    width: 358px;
    height: 360px;
    background: url('../../assets/right-included.svg') no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
}

.included__container {
    align-items: center;
}

.included__title {
    margin: 0 0 56px;
    max-width: 650px;
}

.included__grid {
    display: grid;
    column-gap: 40px;
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1200px) {
    .included {
        padding: 48px 0;
    }
    
    .included::after {
        top: unset;
        bottom: 0;
    }
    
    .included__title {
        margin: 0 0 40px;
    }
    
    .included__grid {
        row-gap: 32px;
        grid-template-columns: minmax(0,1fr);
    }
}