.systemAlert {
    margin-top: 64px;
}

.systemAlert__container {
    align-items: center;
}

.systemAlert h2 {
    margin: 0 0 16px;
}

.systemAlert p {
    margin: 0 0 32px;
}

.systemAlert img {
    margin: 0 auto 24px;
}