.featureItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.featureItem img {
    width: 80px;
    height: 80px;
    display: block;
    margin-bottom: 24px;
}

.featureItem h3 {
    font-weight: 400;
    font-family: var(--heading-font);
    font-size: var(--heading-font-size);
    line-height: var(--heading-line-height);
    color: var(--heading-color);
    margin: 0 0 12px;
    text-align: center;
}

.featureItem p {
    margin: 0;
    text-align: center;
}

.featureItemWhite h3 {
    color: var(--inverted-heading-color);
}

.featureItemWhite p {
    color: var(--inverted-text-color);
}