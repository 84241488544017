.reviews {
    position: relative;
    padding: 80px 0 258px;
}


.reviews::before {
    content: '';
    display: block;
    width: 450px;
    height: 849px;
    background: url('../../assets/left-reviews.svg') no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    bottom: 0;
}

.reviews::after {
    content: '';
    display: block;
    width: 543px;
    height: 865px;
    background: url('../../assets/right-reviews.svg') no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    bottom: 0;
}

.reviews__container {
    align-items: center;
    z-index: 10;
}

.reviews__title {
    margin: 0 0 48px;
}

.reviews__grid {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 0 0 70px;
}

@media (max-width: 1200px) {
    .reviews {
        padding: 48px 0 400px;
        overflow: hidden;
    }
    
    .reviews::before {
        bottom: unset;
        top: 0;
    }

    .reviews__title {
        margin: 0 0 40px;
    }
    
    .reviews__grid {
        flex-direction: column;
        gap: 32px;
        margin: 0;
    }
}