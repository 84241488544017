.learn {
    position: relative;
    overflow: hidden;
}

.learn__container {
    display: flex;
}

.learn__inner {
    padding: 80px 0;
    padding-right: 95px;
    flex: 0 0 50%;
}

.learn__title {
    max-width: 470px;
    margin: 0 0 40px;
}

.learn__grid {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    counter-reset: learn-accordion;
}

.learn__img {
    position: absolute;
    display: block;
    width: 50%;
    height: auto;
    right: 0;
    top: 0;
}

@media (max-width: 1200px) {
    .learn__container {
        flex-direction: column;
    }

    .learn__inner {
        padding: 48px 0 24px;
        padding-right: 0;
        flex: 0 0 100%;
    }

    .learn__title {
        margin: 0 0 40px;
    }

    .learn__img {
        position: relative;
        width: calc(100% + 32px);
        margin: 0 -16px;
        height: auto;
        right: unset;
        top: unset;
    }
}