.hero {
  background: var(--page-bg-color);
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 100vh;
}

.hero__leftDeco {
  display: block;
  width: 29%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  object-position: center left;
  position: absolute;
  left: 0;
  top: 0;
}

.hero__rightDeco {
  display: block;
  width: 29%;
  height: 100%;
  z-index: 1;
  object-fit: contain;
  object-position: center right;
  position: absolute;
  right: 0;
  top: 0;
}

.hero__container {
  z-index: 10;
}

.hero__header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-bottom: 84px;
}

.hero__logo {
  display: block;
  width: 100px;
  height: 100px;
  margin: 0 auto 24px;
}

.hero__title {
  font-weight: 400;
  font-size: var(--page-title-font-size);
  line-height: var(--heading-line-height);
  color: var(--heading-color);
  font-family: var(--heading-font);
  margin: 0;
  text-align: center;
  margin-bottom: 16px;
}

.hero__desc {
  margin: 0 0 32px;
}

.hero__btn {
  width: max-content;
  margin: 0 auto;
}

.hero__social {
  margin-bottom: 40px;
}

.hero__more {
  margin-bottom: 60px !important;
}

@media (max-width: 1200px) {
  .hero__leftDeco {
    opacity: .3;
    width: 70%;
    height: 50%;
    top: unset;
    bottom: -85px;
  }

  .hero__rightDeco {
    width: 70%;
    height: 59%;
    opacity: .25;
  }

  .hero__header {
    margin-bottom: 64px;
  }

  .hero__more {
    margin-bottom: 0 !important;
  }
}