.about {
    padding: 80px 0;
    position: relative;
}

.about__container {
    z-index: 10;
}

.about__quote {
    margin: 0;
    padding: 0;
    font-family: var(--text-font);
    font-size: var(--text-font-size);
    line-height: var(--text-line-height);
    color: var(--text-color);
    position: relative;
    display: block;
    max-width: 560px;
}

.about__quote p {
    margin: 0;
}

.about__quote p:not(:last-child) {
    margin-bottom: 20px;
}

.about__quote::before {
    content: '';
    width: 28px;
    height: 22px;
    background: url("../../assets/icons/quote.svg") no-repeat center / contain;
    position: absolute;
    top: 0;
    left: -40px;
}

.about__quote::after {
    content: '';
    width: 28px;
    height: 22px;
    background: url("../../assets/icons/quote.svg") no-repeat center / contain;
    position: absolute;
    bottom: 0;
    right: -40px;
    rotate: 180deg;
}

.about__bg {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    z-index: 1;
    width: 61.25%;
    height: 100%;
    object-position: right center;
    object-fit: cover;
}

@media (max-width: 1200px) {
    .about {
        padding-top: 48px;
        padding-bottom: 352px;
    }
    
    .about__quote {
        padding: 0 40px;
    }
    
    .about__quote::before {
        left: 0;
    }
    
    .about__quote::after {
        right: 0;
    }
    
    .about__bg {
        top: unset;
        bottom: 0;
        width: 100%;
        height: 320px;
    }
}