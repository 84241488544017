.learnItem {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: none;
}

.learnItem span {
    position: relative;
    font-family: var(--heading-font);
    font-weight: 400;
    font-size: var(--heading-font-size);
    line-height: var(--heading-line-height);
    color: var(--heading-color);

    min-height: 44px;
    display: flex;
    align-items: center;
    padding-left: 68px;
    padding-right: 0;
}

.learnItem span::after {
    position: absolute;
    counter-increment: learn-accordion;
    content: counter(learn-accordion);
    width: 44px;
    height: 44px;
    border: 1px solid var(--secondary-heading-color);
    background: transparent;
    border-radius: 50%;
    font-family: var(--heading-font);
    line-height: var(--heading-line-height);
    color: var(--secondary-heading-color);
    left: 0;
    right: unset;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.learnItem:hover span::after {
    background: var(--secondary-heading-color);
    color: var(--inverted-heading-color);
    rotate: unset;
}

@media (max-width: 1200px) {
    .learnItem span {
        min-height: 32px;
        padding-left: 48px;
    }

    .learnItem span::after {
        width: 32px;
        height: 32px;
    }
}