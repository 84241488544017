.langSwitch {
    display: inline-flex;
    align-items: center;
    gap: 20px;
}

.langSwitch__item {
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    display: inline-flex;
    font-family: var(--secondary-text-font);
    font-size: 16px;
    line-height: var(--text-line-height);
    text-align: center;
    color: var(--text-color);
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
}

.langSwitch__itemActive {
    font-weight: 600;
    color: var(--secondary-heading-color);
}

.langSwitch__item:not(:last-child)::after {
    content: '';
    display: block;
    width: 1px;
    height: 14px;
    background-color: var(--text-color);
    position: absolute;
    right: -10px;
    top: 5px;
}