.flicking-pagination {
    bottom: 0
}

.flicking-viewport {
    padding-bottom: 56px;
}

.has-carousel .flicking-viewport {
    padding: 0;
    width: 100%;
    max-width: 690px;
}

.flicking-pagination-bullet {
    width: 48px;
    height: 2px;
    background: #e4e4e4;
    margin: 0;
    border-radius: 0;

}

.flicking-pagination-bullet-active {
    background: #814624;
    height: 4px;
}

@media (max-width: 1200px) {

    .flicking-viewport {
        padding-bottom: 32px;
    }

    .flicking-pagination-bullet {
        width: 24px;
    }

    .has-carousel .flicking-viewport {
        padding: 0;
    }
    
}