.rules {
    padding-top: 64px;
    padding-bottom: 64px;
}

.rules img {
    display: block;
    margin: 0 auto 32px;
}

.rules h1 {
    margin: 0 0 32px;
}

.rules h2 {
    color: var(--heading-color);
    margin: 24px 0;
}

.rules p {
    margin: 0 0 16px;
}