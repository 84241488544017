.desc {
    font-family: var(--text-font);
    font-size: var(--text-font-size);
    line-height: var(--text-line-height);
    color: var(--text-color);
    font-weight: 400;
}

.descCenter {
    text-align: center;
}

.descLarge {
    font-size: var(--heading-font-size);
}

.descSecondary {
    color: var(--inverted-heading-color);
}

.descLastBold span:last-child {
    font-weight: 700;
    display: block;
}