.title {
    font-weight: 400;
    font-family: var(--heading-font);
    font-size: var(--section-heading-font-size);
    line-height: var(--heading-line-height);
    color: var(--secondary-heading-color);
}

.titleSecondary {
    color: var(--inverted-heading-color);
}

.titleCenter {
    text-align: center;
}

.titleLarge {
    font-size: var(--page-title-font-size);
}