.btn {
  border-radius: 8px;
  padding: 20px 32px;
  background: var(--btn-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-decoration: none;
  border: none;
  outline: none;
  color: var(--btn-color);
  font-size: var(--btn-font-size);
  line-height: var(--btn-line-height);
  font-weight: 400;
  font-family: var(--btn-font);
  transition: all .2s ease-in;
  cursor: pointer;
}

.btn:hover,
.btn:focus {
  opacity: 0.85;
}

.btnLarge {
  padding: 24px 40px;
  font-size: var(--big-btn-font-size);
  line-height: 1;
  gap: 12px;
}

.btnMaxContent {
  width: max-content;
}

.btnCenter {
  margin: 0 auto;
}

.btnSimple {
  padding: 0;
  background: transparent;
}

@media (max-width: 1200px) {
  .btn {
    border-radius: 8px;
    padding: 16px 24px;
  }
  
  .btnLarge {
    padding: 18px 32px;
  }
}
