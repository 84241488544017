.breadCarousel {
    padding-top: 16px;
    padding-bottom: 80px;
}

.breadCarousel__item {
    display: block;
    width: 590px;
    height: 340px;
    object-fit: cover;
    object-position: top center;
    margin-right: 16px;
    -webkit-user-drag: none;
    user-drag: none;
}

.breadCarousel__itemFull {
    display: block;
    width: auto;
    max-height: calc(100vh - 160px);
    margin-right: 16px;
    -webkit-user-drag: none;
    user-drag: none;
    object-fit: contain;
}

.breadCarousel__itemFull img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
}

.carouselModal {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    max-height: calc(100vh - 64px);
    max-width: calc(100% - 64px);
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 8px;
    outline: none;
    padding: 48px 32px 32px;
    z-index: 1001;
}

.carouselModalOverlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}

.carouselModalClose {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
}

.carouselModalClose svg {
    display: block;
    width: 24px;
    height: 24px;
}

@media (max-width: 1200px) {
    .breadCarousel {
        padding-top: 24px;
        padding-bottom: 48px;
    }

    .breadCarousel__item {
        display: block;
        width: 350px;
        height: auto;
    }

    .breadCarousel__itemFull {
        min-width: 340px;
    }

    .carouselModal{
        max-width: calc(100% - 16px);
        padding: 64px 8px 16px;
    }

    .carouselModalClose {
        padding: 0;
    }
}