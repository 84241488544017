.container {
    max-width: 1200px;
    margin: 0 auto;
}

.containerSmall {
    max-width: 560px;
}

.containerMiddle {
    max-width: 1000px;
}

.containerColumn {
    display: flex;
    flex-direction: column;
}

.containerRelative {
    position: relative;
}

@media (max-width: 1200px) {
    .container {
        width: 100%;
        padding: 0 16px;
        margin: 0 auto;
    }
}