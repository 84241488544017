.header {
  padding: 32px 0;
}

.header__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;;
}
