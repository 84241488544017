@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&family=Yeseva+One&display=swap');

* {
  box-sizing: border-box;
}

:root {
  --heading-color: #262f29;
  --secondary-heading-color: #814624;
  --text-color: #797d78;
  --btn-bg-color: #f7d894;
  --btn-color: #814624;
  --page-bg-color: #fff;
  --inverted-heading-color: #F5F5F5;
  --inverted-text-color: rgba(245, 245, 245,.8);

  --heading-font: "Yeseva One", sans-serif;
  --text-font: "Roboto", sans-serif;
  --btn-font: "Yeseva One", sans-serif;
  --secondary-text-font: "Roboto Serif", sans-serif;

  --section-heading-font-size: 40px;
  --heading-font-size: 24px;
  --page-title-font-size: 48px;
  --btn-font-size: 20px;
  --big-btn-font-size: 32px;
  --text-font-size: 18px;

  --heading-line-height: 120%;
  --btn-line-height: 110%;
  --text-line-height: 150%; 
}

@media (max-width: 1200px) {
  :root {
    --section-heading-font-size: 30px;
    --heading-font-size: 20px;
    --page-title-font-size: 32px;
    --btn-font-size: 18px;
    --big-btn-font-size: 24px;
    --text-font-size: 16px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--text-font);
  font-size: var(--text-font-size);
  line-height: var(--text-line-height);
  color: var(--text-color);
  font-weight: 400;
}

main {
  position: relative;
}