.cta {
    position: relative;
    padding: 80px 0;
    background: var(--secondary-heading-color);
    overflow: hidden;
}

.cta::before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: url('../../assets/cta.svg') no-repeat;
    background-size: cover;
    width: 742px;
    height: 650px;
}

.cta__container {
    z-index: 10;
}

.cta__inner {
    max-width: 650px;
}

.cta__title {
    margin: 0 0 16px;
}

.cta__desc {
    margin: 0 0 40px;
    color: var(--inverted-text-color);
}

.cta__tooltip {
    margin: 24px 0 0;
    font-family: var(--text-font);
    font-size: var(--text-font-size);
    line-height: var(--text-line-height);
    display: block;
    color: var(--inverted-heading-color);
}

.cta__img {
    display: block;
    width: 655px;
    height: 655px;
    position: absolute;
    right: -80px;
    top: -60px;
}
@media (max-width: 1200px) {
    .cta {
        padding: 48px 0;
    }
    .cta__img {
        width: calc(100% + 64px);
        margin: 0 -32px;
        height: auto;
        position: relative;
        right: 0;
        top: 0;
    }
}
