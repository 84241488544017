.forWhom {
    padding-top: 48px;
    padding-bottom: 100px;
}

.forWhom__container {
    align-items: center;
}

.forWhom__title {
    margin: 0 0 16px;
    max-width: 650px;
}

.forWhom__desc {
    margin: 0 0 48px;
    max-width: 650px;
}

.forWhom__grid {
    display: flex;
    flex-wrap: nowrap;
    gap: 40px;
}

@media (max-width: 1200px) {
    .forWhom {
        padding-top: 24px;
        padding-bottom: 48px;
        position: relative;
        z-index: 10;
    }

    .forWhom__desc {
        margin: 0 0 40px;
    }
    
    .forWhom__grid {
        flex-direction: column;
        flex-wrap: wrap;
        gap: 32px;
    }
}